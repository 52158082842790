.scroll-top {
  position: fixed;
  width: 80px;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  transition: opacity 0.3s;
}

@media only screen and (max-width: 800px) {
  .scroll-top {
    width: 70px;
    right: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .scroll-top {
    right: 20px;
  }
}

@media only screen and (max-width: 515px) {
  .scroll-top {
    display: none;
  }
}
