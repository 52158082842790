@import "./assets/fonts/fonts.scss";

@mixin fonts($ff, $fs, $fw, $lh, $ls, $ta, $td, $tt, $c) {
  font-family: $ff;
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: $ls;
  text-decoration: $td;
  text-transform: $tt;
  text-align: $ta;
  color: $c;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
}

button {
  cursor: pointer;
  user-select: none;
  outline: none;
}

img {
  user-select: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(224, 224, 224);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(255, 191, 71);
}

::-webkit-scrollbar-thumb:hover {
  background: orange;
}

.container {
  margin: 0 auto;
  padding: 0 15%;
}

.pages {
  margin-top: 20px;
  margin-left: 15px;
  display: flex;

  div {
    margin: 0 10px;
    cursor: default;
  }

  div,
  a {
    @include fonts(OpenSans, 15px, 400, 20px, 0, none, none, none, #333333);
    text-wrap: nowrap;
  }

  a {
    &:not(.active) {
      transition: color 0.3s;

      &:hover {
        color: orange;
      }
    }
  }
}

.hidden {
  opacity: 0;
  transition: opacity 1s;
}

.hiddenLeft {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100px);
  transition: opacity 1s, filter 1s, transform 1s;
}

.hiddenTop {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-100px);
  transition: opacity 1s, filter 1s, transform 1s;
}

.hiddenBottom {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(100px);
  transition: opacity 1s, filter 1s, transform 1s;
}

.hiddenRight {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100px);
  transition: opacity 1s, filter 1s, transform 1s;
}

.hiddenAnimation {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100px);
}

.show {
  opacity: 1;
}

.showLeft {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.showTop {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}

.showBottom {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}

.showRight {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.showAnimation {
  animation: hidden-anim 1s forwards;
}

@media only screen and (max-width: 606px) {
  .pages {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 515px) {
  .container {
    margin: 0 auto;
    padding: 0 20px;
  }
}
